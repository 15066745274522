const BOMenu = [
  {
    title: 'Dashboard',
    is_heading: false,
    is_active: false,
    link: {
      name: 'bo.dashboard'
    },
    class_name: 'mb-3',
    is_icon_class: true,
    icon: 'fat fa-objects-column',
    active_icon: 'fad fa-objects-column'
  },
  {
    title: 'Maintenances',
    is_heading: true,
    is_active: false,
    link: {
      name: 'bo.maintenances'
    },
    class_name: 'font-weight-bolder'
  },
  {
    title: 'Fiscal Years',
    is_heading: false,
    is_active: false,
    link: {
      name: 'bo.maintenances.fiscal-years'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'fat fa-calendar-lines',
    active_icon: 'fad fa-calendar-lines'
  },
  {
    title: 'Budget Centers',
    is_heading: false,
    is_active: false,
    link: {
      name: 'bo.maintenances.budget-centers'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'fat fa-suitcase',
    active_icon: 'fad fa-suitcase'
  },
  {
    title: 'Items',
    is_heading: false,
    is_active: false,
    link: {
      name: 'bo.maintenances.items'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'fat fa-briefcase',
    active_icon: 'fad fa-briefcase'
  },
  {
    title: 'Item Centers',
    is_heading: false,
    is_active: false,
    link: {
      name: 'bo.maintenances.item-centers'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'fat fa-briefcase-blank',
    active_icon: 'fad fa-briefcase-blank'
  }
]

export default BOMenu
