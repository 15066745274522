const DAssociateMenu = [
  {
    title: 'Dashboard',
    is_heading: false,
    is_active: false,
    link: {
      name: 'da.dashboard'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'fat fa-objects-column',
    active_icon: 'fad fa-objects-column'
  },
  {
    title: 'Payment Vouchers',
    is_heading: false,
    is_active: false,
    link: {
      name: 'da.payment-vouchers'
    },
    class_name: 'mb-3',
    is_icon_class: true,
    icon: 'fat fa-money-check',
    active_icon: 'fad fa-money-check'
  },
  {
    title: 'Payment Requests',
    is_heading: true,
    is_active: false,
    link: {
      name: 'da.payment-requests'
    },
    class_name: 'font-weight-bolder'
  },
  {
    title: 'Document Printing',
    is_heading: false,
    is_active: false,
    link: {
      name: 'da.payment-requests.document-printing'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'fat fa-print',
    active_icon: 'fad fa-print'
  },
  {
    title: 'Check Signing',
    is_heading: false,
    is_active: false,
    link: {
      name: 'da.payment-requests.check-signing'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'fat fa-pen-field',
    active_icon: 'fad fa-pen-field'
  },
  {
    title: 'Releasing',
    is_heading: false,
    is_active: false,
    link: {
      name: 'da.payment-requests.releasing'
    },
    class_name: 'mb-3',
    is_icon_class: true,
    icon: 'fat fa-chart-user',
    active_icon: 'fad fa-chart-user'
  },
  {
    title: 'Reports',
    is_heading: true,
    is_active: false,
    link: {
      name: 'da.reports'
    },
    class_name: 'font-weight-bolder'
  },
  {
    title: 'Cash',
    is_heading: false,
    is_active: false,
    link: {
      name: 'da.reports.cash'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'fat fa-file-invoice-dollar pl-1',
    active_icon: 'fad fa-file-invoice-dollar pl-1'
  },
  {
    title: 'Checks',
    is_heading: false,
    is_active: false,
    link: {
      name: 'da.reports.checks'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'fat fa-credit-card-front',
    active_icon: 'fad fa-credit-card-front'
  },
  {
    title: 'Credit Cards',
    is_heading: false,
    is_active: false,
    link: {
      name: 'da.reports.credit-cards'
    },
    class_name: 'mb-3',
    is_icon_class: true,
    icon: 'fat fa-credit-card',
    active_icon: 'fad fa-credit-card'
  }
]

export default DAssociateMenu
