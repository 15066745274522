const ESMenu = [
  {
    title: 'Dashboard',
    is_heading: false,
    is_active: false,
    link: {
      name: 'es.dashboard'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-dashboard-line',
    active_icon: 'ri-dashboard-fill'
  },
  // {
  //   title: 'Check Receiving',
  //   is_heading: false,
  //   is_active: false,
  //   link: {
  //     name: 'es.payment-requests.check-receiving'
  //   },
  //   class_name: '',
  //   is_icon_class: true,
  //   icon: 'ri-folder-received-line',
  //   active_icon: 'ri-folder-received-fill'
  // },
  {
    title: 'Check Releasing',
    is_heading: false,
    is_active: false,
    link: {
      name: 'es.payment-requests.check-releasing'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'fat fa-chart-user',
    active_icon: 'fad fa-chart-user'
  }
]

export default ESMenu
