const TAMenu = [
  {
    title: 'Dashboard',
    is_heading: false,
    is_active: false,
    link: {
      name: 'ta.dashboard'
    },
    class_name: 'mb-3',
    is_icon_class: true,
    icon: 'fat fa-objects-column',
    active_icon: 'fad fa-objects-column'
  },
  {
    title: 'Maintenances',
    is_heading: true,
    is_active: false,
    link: {
      name: 'ta.maintenances'
    },
    class_name: 'font-weight-bolder'
  },
  {
    title: 'Tax Codes',
    is_heading: false,
    is_active: false,
    link: {
      name: 'ta.maintenances.tax-codes'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'fat fa-qrcode',
    active_icon: 'fad fa-qrcode'
  },
  {
    title: 'Document Types',
    is_heading: false,
    is_active: false,
    link: {
      name: 'ta.maintenances.document-types'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'fat fa-copy',
    active_icon: 'fad fa-copy'
  },
  {
    title: 'Payment Natures',
    is_heading: false,
    is_active: false,
    link: {
      name: 'ta.maintenances.payment-natures'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'fat fa-file-invoice-dollar',
    active_icon: 'fad fa-file-invoice-dollar'
  },
  {
    title: 'Business Partners',
    is_heading: false,
    is_active: false,
    link: {
      name: 'ta.maintenances.business-partners'
    },
    class_name: 'mb-3',
    is_icon_class: true,
    icon: 'fat fa-user-group',
    active_icon: 'fad fa-user-group'
  },
  {
    title: 'Reports',
    is_heading: true,
    is_active: false,
    link: {
      name: 'sidebar.ta.reports'
    },
    class_name: 'font-weight-bolder'
  },
  {
    title: 'QA of Payees',
    is_heading: false,
    is_active: false,
    link: {
      name: 'ta.reports.qap'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'fat fa-file-spreadsheet',
    active_icon: 'fad fa-file-spreadsheet'
  },
  {
    title: 'Vat Details',
    is_heading: false,
    is_active: false,
    link: {
      name: 'ta.reports.vat-details'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'fat fa-file-spreadsheet',
    active_icon: 'fad fa-file-spreadsheet'
  }
]

export default TAMenu
