const DHeadMenu = [
  {
    title: 'Dashboard',
    is_heading: false,
    is_active: false,
    link: {
      name: 'dh.dashboard'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'fat fa-objects-column',
    active_icon: 'fad fa-objects-column'
  },
  {
    title: 'Payment Vouchers',
    is_heading: false,
    is_active: false,
    link: {
      name: 'dh.payment-vouchers'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'fat fa-money-check',
    active_icon: 'fad fa-money-check'
  }
]

export default DHeadMenu
