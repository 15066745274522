const UserMenu = [
  {
    title: 'Dashboard',
    is_heading: false,
    is_active: false,
    link: {
      name: 'user.dashboard'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'fat fa-objects-column',
    active_icon: 'fad fa-objects-column'
  },
  {
    title: 'Payment Requests',
    is_heading: false,
    is_active: false,
    link: {
      name: 'user.expenses.payment-requests'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'fat fa-file',
    active_icon: 'fad fa-file'
  },
  {
    title: 'Request Approvals',
    is_heading: false,
    is_active: false,
    link: {
      name: 'user.request-approvals'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'fat fa-file-signature',
    active_icon: 'fad fa-file-signature'
  }
]

export default UserMenu
