const AdminMenu = [
  {
    title: 'Dashboard',
    is_heading: false,
    is_active: false,
    link: {
      name: 'admin.dashboard'
    },
    class_name: 'mb-3',
    is_icon_class: true,
    icon: 'fat fa-objects-column',
    active_icon: 'fad fa-objects-column'
  },
  {
    title: 'Accounts',
    is_heading: true,
    is_active: false,
    link: {
      name: 'admin.accounts'
    },
    class_name: 'font-weight-bolder'
  },
  {
    title: 'Users',
    is_heading: false,
    is_active: false,
    link: {
      name: 'admin.accounts.users'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'fat fa-user-lock',
    active_icon: 'fad fa-user-lock'
  },
  {
    title: 'Employees',
    is_heading: false,
    is_active: false,
    link: {
      name: 'admin.accounts.employees'
    },
    class_name: 'mb-3',
    is_icon_class: true,
    icon: 'fat fa-user-tie-hair',
    active_icon: 'fad fa-user-tie-hair'
  },
  {
    title: 'Maintenances',
    is_heading: true,
    is_active: false,
    link: {
      name: 'admin.maintenances'
    },
    class_name: 'font-weight-bolder'
  },
  {
    title: 'Banks',
    is_heading: false,
    is_active: false,
    link: {
      name: 'admin.maintenances.banks'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'fat fa-building-columns',
    active_icon: 'fad fa-building-columns'
  }
]

export default AdminMenu
