<template>
  <div class="iq-top-navbar">
    <div class="iq-navbar-custom">
      <div class="iq-sidebar-logo">
        <div class="top-logo">
          <router-link
            :to="{ name: `${user ? user.role : 'admin'}.dashboard`}"
            class="logo"
          >
            <div class="iq-light-logo">
              <img
                src="@/assets/images/logo.gif"
                class="img-fluid"
                alt="logo"
              >
            </div>
            <div class="iq-dark-logo">
              <img
                src="@/assets/images/logo-dark.gif"
                class="img-fluid"
                alt="logo"
              >
            </div>
            <span>{{ appName }}</span>
          </router-link>
        </div>
      </div>
      <nav class="navbar navbar-expand-lg navbar-light p-0 d-flex justify-content-between">
        <div
          class="d-inline-block"
          style="height: 75px"
        >
          <div class="d-flex h-100 p-3">
            <div
              class="wrapper-menu align-self-center p-2 rounded"
              style="background: #f8f7fd;"
              @click="toggleSidebar"
            >
              <div class="main-circle">
                <i class="fad fa-xs fa-bars" />
              </div>
              <div class="hover-circle">
                <i class="fad fa-xs fa-bars" />
              </div>
            </div>
          </div>
        </div>
        <ul class="navbar-list">
          <li
            v-nav-toggle
            class="rounded"
          >
            <a
              href="javascript:void(0)"
              class="search-toggle iq-waves-effect d-flex align-items-center bg-primary rounded px-2"
            >
              <!-- <img
                :src="transformToImageUrl(getProfile())"
                class="img-fluid rounded mr-3"
                alt="user"
              > -->
              <b-avatar
                :text="getUserAbrevation"
                size="lg"
                variant="light"
                rounded
                class="font-weight-bolder"
              />
              <div
                class="caption"
                style="min-width: 195px; margin-left: 14.5px;"
              >
                <h6 class="mb-0 line-height text-white">
                  {{ user ? user.name : null }}
                </h6>
                <span class="font-size-12 text-white">
                  {{ user ? user.role_name : null }}
                </span>
              </div>
            </a>
            <div class="iq-sub-dropdown iq-user-dropdown mt-1">
              <div class="iq-card shadow-none m-0">
                <div class="iq-card-body p-0 ">
                  <div class="bg-primary text-center p-3 d-block d-lg-none d-md-none">
                    <h5 class="mb-0 text-white line-height">
                      {{ user ? user.name : null }}
                    </h5>
                    <span class="font-size-12 text-white">
                      {{ user ? user.role_name : null }}
                    </span>
                  </div>
                  <b-overlay
                    rounded
                    opacity="1"
                    spinner-variant="success"
                    :show="state.busy"
                    class="px-1 py-1"
                  >
                    <router-link
                      :to="{name: 'shared.change-password'}"
                    >
                      <div class="d-flex align-items-center px-2 py-2 bg-hover-primary-light rounded-0">
                        <div class="px-2">
                          <i class="fad fa-key h5 text-success mb-0" />
                        </div>
                        <div class="flex-grow-1 rounded-0">
                          <h6 class="mb-0 ">
                            Change Password
                          </h6>
                        </div>
                      </div>
                    </router-link>
                    <!-- <router-link
                      :to="{name: 'shared.change-password'}"
                      class="iq-sub-card iq-bg-success-hover"
                    >
                      <div class="media align-items-center">
                        <div class="media-body d-flex px-2">
                          <i class="ri-exchange-line px-2 h5" />
                          <h6 class="mb-0 ">
                            Switch Account
                          </h6>
                        </div>
                      </div>
                    </router-link> -->
                    <b-link
                      href="javascript:void(0)"
                      :disabled="state.busy"
                      @click="logout"
                    >
                      <div class="d-flex align-items-center px-2 py-2 border-top bg-hover-primary-light rounded-0">
                        <div class="px-2">
                          <i class="fad fa-sign-out h5 text-danger mb-0" />
                        </div>
                        <div class="flex-grow-1 rounded-0">
                          <h6 class="mb-0 text-danger">
                            Log Out
                          </h6>
                        </div>
                      </div>
                    </b-link>
                  </b-overlay>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { core, APPNAME } from '@/config/pluginInit'
import { SharedAuthService } from '@/services'
import url from '@/mixins/url'
import logos from '@/mixins/logos'

export default {
  name: 'Header',

  mixins: [url, logos],

  data () {
    return {
      state: {
        busy: false
      },
      appName: APPNAME
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user'
    }),
    getUserAbrevation () {
      if (this.user?.name) {
        const fullname = this.user.name.split(' ')
        return fullname[0][0]
      }
      return ''
    }
  },

  methods: {
    ...mapActions({
      rtlAdd: 'setting/setRtlAction',
      rtlRemove: 'setting/removeRtlAction'
    }),

    toggleSidebar () {
      core.triggerSet()
    },

    async logout () {
      if (!this.state.busy) {
        this.state.busy = true
        await SharedAuthService.Logout().then(() => {
          this.$store.dispatch('auth/logout')
          this.$router.replace({ name: 'shared.login' })
        }).finally(() => {
          this.state.busy = false
        })
      }
    }
  }
}
</script>
